import cn from 'classnames';

import type {ConversionProps} from '@/components/base/sections/Conversion/Conversion';
import {useSiteData} from '@/hooks/useSiteData';
import Section from '@/components/base/layouts/Section/Section';
import EmailForm from '@/components/base/modules/EmailForm/EmailForm';
import Typography from '@/components/base/elements/Typography/Typography';
import {useSignupPaidTrialMessage} from '@/hooks/experiments/useSignupPaidTrialMessage';
import {useTranslations} from '@/components/shared/Page/I18N';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import SignupPaidTrialPromo from '@/components/shared/SignupPaidTrialPromo/SignupPaidTrialPromo';

const LANGUAGES_WITH_ALT_ROTATION = ['de', 'es', 'fr', 'ja', 'pt', 'it'];
const LANGUAGES_WITH_PROMO_BADGE = ['de', 'es', 'fr', 'ja', 'pt', 'it', 'en'];

interface ConversionFormProps extends ConversionProps {
  emailFormButtonText?: string;
  emailFormWidth?: 'max-w-md' | 'max-w-lg';
  isBadgesAvailable?: boolean;
}

export default function ConversionForm({
  emailFormButtonText,
  emailFormWidth,
  isBadgesAvailable,
  ...props
}: ConversionFormProps) {
  const {site} = useSiteData();
  const {t} = useTranslations();
  const languageCode = site.languageCode || site.locale?.split('-')[0] || 'en';
  const hasPromoBadge = LANGUAGES_WITH_PROMO_BADGE.includes(languageCode);
  const hasAltPromoBadgeRotation =
    LANGUAGES_WITH_ALT_ROTATION.includes(languageCode);
  const isExpSignupPaidTrialMessage = useSignupPaidTrialMessage();

  let className = cn(
    'relative text-white pb-28',
    // TODO: see if 'legacy-cyan-80' can be passed instead
    '[background:#054a49_radial-gradient(circle_at_-68%_100%,_#d0f224aa_24%,_#054a49_55%)] md:[background:#054a49_radial-gradient(circle_at_-48%_130%,_#d0f224aa_24%,_#054a49_45%)] bg-no-repeat bg-position-[top_20%_left]',
    {
      // Right Badge: Sell Everything, Everywhere
      'before:absolute default:before:content-free-trial-conversion-last-before default:before:-top-[70px] default:before:-right-1':
        hasPromoBadge && !isBadgesAvailable,
      'default:[@media(max-width:640px)]:before:-right-[29px] [@media(max-width:640px)]:before:scale-70':
        hasPromoBadge && !isBadgesAvailable,
      // Left Badge: From Hello World to IPO
      'after:absolute default:after:content-free-trial-conversion-last-after default:after:-bottom-[70px] default:after:left-[35px]':
        hasPromoBadge && !isBadgesAvailable,
      'default:[@media(max-width:640px)]:after:-left-[22px] [@media(max-width:640px)]:after:scale-70':
        hasPromoBadge && !isBadgesAvailable,
      // common styles for overrides
      'before:rotate-[17deg] before:-right-[35px] [@media(max-width:640px)]:before:-right-[67px] before:-top-[50px]':
        hasAltPromoBadgeRotation,
      'after:rotate-[-17deg] [@media(max-width:640px)]:after:-left-[3px]':
        hasAltPromoBadgeRotation,
      // German
      'before:content-free-trial-conversion-last-before-de':
        languageCode === 'de',
      'after:content-free-trial-conversion-last-after-de':
        languageCode === 'de',
      // Spanish
      'before:content-free-trial-conversion-last-before-es':
        languageCode === 'es',
      'after:content-free-trial-conversion-last-after-es':
        languageCode === 'es',
      // French
      'before:content-free-trial-conversion-last-before-fr':
        languageCode === 'fr',
      'after:content-free-trial-conversion-last-after-fr':
        languageCode === 'fr',
      // Italian
      'before:content-free-trial-conversion-last-before-it':
        languageCode === 'it',
      'after:content-free-trial-conversion-last-after-it':
        languageCode === 'it',
      // Japanese
      'before:content-free-trial-conversion-last-before-jp':
        languageCode === 'ja',
      'after:content-free-trial-conversion-last-after-jp':
        languageCode === 'ja',
      // Brazilian Portuguese
      'before:content-free-trial-conversion-last-before-pt':
        languageCode === 'pt',
      'after:content-free-trial-conversion-last-after-pt':
        languageCode === 'pt',
    },
  );

  return (
    <Section className={className}>
      <SectionHeader
        center
        className="relative text-white text-body-lg"
        {...props}
      />

      <div>
        <div className={`${emailFormWidth} mx-auto px-4 mb-2 md:mb-6`}>
          <EmailForm
            button={{
              children: emailFormButtonText,
            }}
          />
        </div>

        <div className="h-lg w-full pb-14 sm:pb-0">
          {isExpSignupPaidTrialMessage ? (
            <SignupPaidTrialPromo
              mode="dark"
              className="max-w-m text-center px-6"
              is1F
            />
          ) : (
            <Typography
              size="body-sm"
              className="max-w-m text-center px-6"
              as="p"
            >
              {t('global:signup.disclaimerHtml')}
            </Typography>
          )}
        </div>
      </div>
    </Section>
  );
}
